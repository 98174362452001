import React, { useLayoutEffect } from 'react';
import { StepMessage } from '../Components/StepMessage';
import { StackScreenProps } from '@react-navigation/stack';
import { HomeScreenName, RootStackParamList } from '../../screen-config';

const ReturnFromCFScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Return From CF'>) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  return (
    <StepMessage
      text={
        'We will take you back to the Waffle app. ASPCA Pet Insurance will contact you to finalize.'
      }
      onPress={async () => navigation.navigate(HomeScreenName)}
    />
  );
};

export default ReturnFromCFScreen;
