import React, { useLayoutEffect } from 'react';
import { Button } from 'react-native';
import { WebView } from 'react-native-webview';
import Spinner from '../Components/Spinner';
import { PlainView } from '../Components/SimpleComponents';
import styled from 'styled-components/native';
import WaffleText from '../Components/WaffleText';
import { ArchMemberCenterUrl } from '../Helper/ArchHelper';
import { LogoLargeImage } from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList, StepMessageScreenName } from '../../screen-config';

// todo : combine with CFMemberCenterScreen!

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

const Text = styled(WaffleText)`
  font-size: 26px;
  line-height: 30px;
  margin-top: 13px;
  margin-bottom: 13px;
  text-align: center;
`;

const Loading = () => (
  <PlainView
    style={{
      position: 'absolute',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      backgroundColor: 'white',
    }}>
    <LogoImage source={LogoLargeImage} />
    <Text>Preparing...</Text>
    <Spinner />
  </PlainView>
);

const ArchMemberCenterScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Arch Member Center'>) => {
  const uri = ArchMemberCenterUrl;

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Arch RoamRight Claims',
      headerRight: () => (
        <Button
          onPress={() =>
            navigation.navigate(StepMessageScreenName, {
              nextScreen: 'Home',
              message: 'We will now take you back to the Waffle app.',
            })
          }
          title="Done"
          color="#000"
        />
      ),
      gestureEnabled: false,
    });
  }, [navigation]);

  return (
    <WebView
      source={{ uri }}
      startInLoadingState={true}
      renderLoading={() => <Loading />}
    />
  );
};

export default ArchMemberCenterScreen;
