import { useQuery } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import { GetFinalizedUserPolicies } from '../../../operation-result-types';
import { USER_POLICIES } from '../GraphQL/Waffle/Queries';
import { StepMessage } from '../Components/StepMessage';
import { ProductHome } from '../Constants/Products';
import CustomLinking from '../Helper/CustomLinking';

const ConnectToHippoScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Connect to Hippo'>) => {
  const { data } = useQuery<GetFinalizedUserPolicies>(USER_POLICIES, {
    fetchPolicy: 'network-only',
  });
  const uri = data?.finalizedUserPolicies?.find(
    (p) => p.productId === ProductHome
  )?.externalPolicy?.ContinuationURL;

  async function onStepMessagePressAsync() {
    CustomLinking.openURL(uri);
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Connect to Hippo',
      headerBackTitleVisible: false,
      gestureEnabled: false,
      headerShown: isNativeOrMobileWeb(),
    });
  }, [navigation]);

  return (
    <StepMessage
      text={
        'We will now connect you with Hippo Insurance ' +
        'to continue your application for Home insurance.'
      }
      onPress={onStepMessagePressAsync}
    />
  );
};

export default ConnectToHippoScreen;
