import { useApolloClient, useQuery } from '@apollo/client';
import React, { useLayoutEffect, useState } from 'react';

import * as yup from 'yup';
import WaffleCreateHeader from '../Components/WaffleCreateHeader';
import PasswordInput from '../Components/PasswordInput';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { ErrorDisplay } from '../Components/ErrorDisplay';
import { KeyboardViewMaybe } from '../Components/KeyboardViewMaybe';
import { VerticalCenter } from '../Components/SimpleLayouts';
import style, { WaffleOrange } from '../Constants/Style';
import { MediumText } from '../Components/WaffleText';
import { CreateAspcaMemberAccount } from '../Helper/CFHelper';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';
import { StackScreenProps } from '@react-navigation/stack';
import {
  RootStackParamList,
  PurchaseCompleteScreenName,
} from '../../screen-config';
import { useAuthContext } from '../Screens/AuthProvider';
import { FetchGuestToken } from '../Helper/UserHelpers';
import { ParseJwt } from '../Helper/MiscFunctions';
import AuthenticationService from '../Helper/AuthenticationService';
import { Login } from '../Helper/AuthHelper';

const schema = yup.object().shape({
  password: yup.string().min(5).required(),
});

interface State {
  password?: string;
  confirmPassword?: string;
}

const isValid = (state: State) =>
  schema.isValidSync(state) && state.password === state.confirmPassword;

const CFMemberCenterPasswordScreen = ({
  navigation,
  route,
}: StackScreenProps<RootStackParamList, 'CF Member Center Password'>) => {
  const client = useApolloClient();
  const last4 = route.params?.last4 ?? '';
  const accountNumber = route.params?.accountNumber ?? '';
  const {
    data: { user },
  } = useQuery(GET_LOCAL_USER);
  const [state, setState] = useState<State>({});
  const [error, setError] = useState('');
  const disabled = !isValid(state);
  const { isGuest, setIsSignedIn } = useAuthContext();
  const message = isGuest
    ? 'To access your policy documents, submit a claim and add further products, please create am account. This password will also provide access to the pet portal.'
    : 'Please enter a password to access your Pet portal. This can be the same or different to the password you use to access Waffle.';

  const onChangeText = (key: keyof State, text: string) =>
    setState({ ...state, [key]: text });

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  const nextScreen = async () => {
    await CreateAspcaMemberAccount(user, state.password, accountNumber, last4);

    // If guest, we go ahead and create the user login right now.
    if (isGuest) {
      const token = await FetchGuestToken();
      const obj = ParseJwt(token);

      const { userConfirmed } = await AuthenticationService.SignUp(
        client,
        user.email,
        state.password,
        { 'custom:user_id': obj?.sub }
      );

      if (userConfirmed) {
        await Login(
          navigation,
          client,
          user.email,
          state.password,
          setIsSignedIn
        );
      }
      // if not confirmed... then what?

      // also, should probably consistently go to exit...
    }

    navigation.navigate(PurchaseCompleteScreenName);
  };

  return (
    <KeyboardViewMaybe>
      <WaffleCreateHeader title={'Pet Portal'} />

      <VerticalCenter style={[style.fullWidth, style.fullHeight]}>
        <ErrorDisplay error={error} />

        <VerticalCenter style={{ marginHorizontal: 45 }}>
          <MediumText>{message}</MediumText>
        </VerticalCenter>

        <Spacer y={2} />

        <PasswordInput
          label={'Password'}
          onChangeText={(text: string) => onChangeText('password', text)}
          otherProps={{
            textContentType: 'newPassword',
          }}
          containerStyle={{
            width: 300,
          }}
          footerText={'8 or more characters'}
        />

        <Spacer y={1} />

        <PasswordInput
          label={'Confirm Password'}
          onChangeText={(text: string) => onChangeText('confirmPassword', text)}
          otherProps={{
            textContentType: 'newPassword',
          }}
          containerStyle={{
            width: 300,
          }}
        />

        <Spacer y={2} />

        <PlainView style={{ alignItems: 'center' }}>
          <WaffleButtonAsync
            style={{ backgroundColor: disabled ? '#E0E0E0' : WaffleOrange }}
            disabled={disabled}
            onPress={nextScreen}
            name={'Next'}
          />
        </PlainView>
      </VerticalCenter>
    </KeyboardViewMaybe>
  );
};

export default CFMemberCenterPasswordScreen;
