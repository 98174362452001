import { useQuery } from '@apollo/client';
import React, { useLayoutEffect } from 'react';
import { StepMessage } from '../Components/StepMessage';
import { StackScreenProps } from '@react-navigation/stack';
import { RootStackParamList } from '../../screen-config';
import { Platform } from 'react-native';
import CustomLinking from '../Helper/CustomLinking';
import { getAspcaBridgeUrl } from '../Helper/FeatureFlags';
import { GetFinalizedUserPolicies } from '../../../operation-result-types';
import { USER_POLICIES } from '../GraphQL/Waffle/Queries';
import { ProductPet } from '../Constants/Products';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

const ConnectToCFScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Connect To CF'>) => {
  const { data } = useQuery<GetFinalizedUserPolicies>(USER_POLICIES, {
    fetchPolicy: 'network-only',
  });

  const userTracking = useUserTracking();

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  async function onStepMessagePressAsync() {
    if (Platform.OS === 'web') {
      const petPolicy = data?.finalizedUserPolicies?.find(
        (p) => p.productId === ProductPet
      );

      await userTracking?.publishOrderTransferred(petPolicy);
      const baseUrl = getAspcaBridgeUrl();
      const uri = `${baseUrl}/${petPolicy?.externalQuoteId}`;

      CustomLinking.openURL(uri);
    }
  }

  return (
    <StepMessage
      text={
        'Get your credit card ready! We will now connect you to ASPCA Pet Health Insurance to finalize your policy.'
      }
      onPress={onStepMessagePressAsync}
    />
  );
};

export default ConnectToCFScreen;
