import { useQuery } from '@apollo/client';
import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from 'react';
import styled from 'styled-components/native';
import { PlainView } from '../Components/SimpleComponents';
import WaffleText from '../Components/WaffleText';
import { VerticalCenter, VerticalPacked } from '../Components/SimpleLayouts';
import {
  IsCyberPolicy,
  PolicyLongFrequencyWord,
  TotalPolicyPrice,
} from '../Helper/PolicyHelper';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import { LogoLargeImage } from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import {
  CreateLoginScreenName,
  HomeScreenName,
  RootStackParamList,
} from '../../screen-config';
import { USER_POLICIES } from '../GraphQL/Waffle/Queries';
import { GetFinalizedUserPolicies } from '../../../operation-result-types';
import { FinalizedPolicy, FinalizedPolicyProps } from '../../component-config';
import { ContentUnified } from '../Components/ContentShared';
import { Fade, Placeholder, PlaceholderLine } from 'rn-placeholder';
import { useAuthContext } from '../Screens/AuthProvider';
import { useFocusEffect } from '@react-navigation/native';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

const LargeText = styled(WaffleText)`
  color: #000;
  font-size: 37px;
  font-weight: 700;
  line-height: 42px;
  text-align: center;
`;

const Text = styled(WaffleText)`
  color: #000;
  font-size: 20px;
  text-align: center;
  width: 250px;
`;

const Box = styled(PlainView)`
  width: 290px;
  border-width: 1px;
  padding: 10px;
  margin: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LabelText = styled(WaffleText)`
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  height: 25px;
`;

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

const placeholderWidth = 200;

const PolicyPaymentBox = ({ policy }: FinalizedPolicyProps) => {
  if (policy) {
    const amt = TotalPolicyPrice(policy).toFixed(2);
    const frequency = PolicyLongFrequencyWord(policy);

    return (
      <Box>
        <LabelText>Your {frequency} Payment</LabelText>
        <Text>${amt}</Text>
      </Box>
    );
  } else {
    return (
      <Box>
        <Placeholder
          Animation={Fade}
          style={{
            width: placeholderWidth,
            height: 50,
            marginTop: 6,
          }}>
          <PlaceholderLine width={placeholderWidth / 2} height={15} />
          <PlaceholderLine width={placeholderWidth / 2} height={15} />
        </Placeholder>
      </Box>
    );
  }
};

const PolicyNumber = ({
  externalPolicyNumber,
}: {
  externalPolicyNumber?: string;
}) => {
  if (externalPolicyNumber) {
    return <Text>{externalPolicyNumber}</Text>;
  } else {
    return (
      <Placeholder
        Animation={Fade}
        style={{
          width: placeholderWidth,
          height: 20,
          marginTop: 6,
        }}>
        <PlaceholderLine width={placeholderWidth / 2} height={15} />
      </Placeholder>
    );
  }
};

const ChubbPolicy = ({ policy }: FinalizedPolicyProps) => {
  return (
    <VerticalPacked style={{ marginBottom: 20 }}>
      <Box>
        <LabelText>Your Protection</LabelText>
        <Text>Blink&#8480; Cyber Protection</Text>
      </Box>

      <Box>
        <LabelText>Your Blink Policy</LabelText>

        <PolicyNumber externalPolicyNumber={policy?.externalPolicyNumber} />

        <Text>
          The policy can be accessed in View Your Policy from the App Home
          screen.
        </Text>
      </Box>

      <PolicyPaymentBox policy={policy} />
    </VerticalPacked>
  );
};

const ChubCompleteScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Chubb Complete'>) => {
  const { isGuest, user } = useAuthContext();
  const { data, refetch, loading } = useQuery<GetFinalizedUserPolicies>(
    USER_POLICIES,
    {
      fetchPolicy: 'network-only',
    }
  );
  const userTracking = useUserTracking();
  const [purchaseIsCompleted, setPurchaseIsCompleted] = useState(false);

  useEffect(() => {
    console.info('chubb is loading ' + loading);
    const policy: FinalizedPolicy =
      data?.finalizedUserPolicies?.find(IsCyberPolicy);

    console.info('puchache complete' + purchaseIsCompleted);
    if (policy && !purchaseIsCompleted) {
      userTracking?.publishCyberOrderCompleted(policy);

      setPurchaseIsCompleted(true);
    }

    return () => {
      console.info('Chubb completed unmounting');
    };
  }, [data]);

  const policy: FinalizedPolicy =
    data?.finalizedUserPolicies?.find(IsCyberPolicy);

  const navigateNextAsync = async () => {
    if (isGuest) {
      await navigation.navigate(CreateLoginScreenName, {
        initialEmail: user.email,
        products: ['Cyber'],
      });
    } else {
      await navigation.navigate(HomeScreenName);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      refetch().then();
    }, [])
  );

  // Logger(`ChubbComplete policy=${JSON.stringify(data)}`);

  return (
    <ContentUnified title={'Payment Complete'} addingProduct={true}>
      <VerticalCenter style={{ flex: 1 }}>
        <LogoImage resizeMode={'contain'} source={LogoLargeImage} />

        <LargeText style={{ marginBottom: 20 }}>Congratulations!</LargeText>

        <Text style={{ marginBottom: 20 }}>{"You're now protected!"}</Text>

        <ChubbPolicy policy={policy} />

        <WaffleButtonAsync
          name={'Continue'}
          onPress={navigateNextAsync}
          disabled={!policy}
          style={{
            marginBottom: 18,
          }}
        />
      </VerticalCenter>
    </ContentUnified>
  );
};

export default ChubCompleteScreen;
