import { useApolloClient } from '@apollo/client';
import React, { useCallback, useLayoutEffect, useState } from 'react';

import { useFocusEffect } from '@react-navigation/native';
import styled from 'styled-components/native';

import { VerticalCenter } from '../Components/SimpleLayouts';
import WaffleText from '../Components/WaffleText';
import { Logger } from '../Helper/Logger';
import { FINALIZE_DRAFT_USER_POLICIES } from '../GraphQL/Waffle/Queries';
import { UpdateUserStateOnServer } from '../Helper/UserHelpers';
import { USER_STATE_COMPLETE } from '../Helper/NavigationService';
import Spinner from '../Components/Spinner';
import { LogoLargeImage } from '../Constants/Style';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ChubbCompleteScreenName,
  RootStackParamList,
} from '../../screen-config';
import { ContentUnified } from '../Components/ContentShared';

const LargeText = styled(WaffleText)`
  color: #000;
  font-size: 37px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
`;

const ErrorString = styled(WaffleText)`
  font-size: 30px;
  font-weight: normal;
  max-width: 300px;
  line-height: 35px;
  text-align: center;
`;

const LogoImage = styled.Image`
  width: 219px;
  height: 54px;
  margin-bottom: 36px;
`;

const finalize = async (client) => {
  Logger(`Cyber: payment complete.  Starting finalize`);

  // Note: Since this screen should only involve Chubb as a draft policy, the payment method is not used,
  //   so we can pass in empty string.
  const { data } = await client.mutate({
    mutation: FINALIZE_DRAFT_USER_POLICIES,
    variables: { paymentMethodId: '' },
  });
  const responses = data?.finalizeDraftUserPolicies?.responses;

  if (responses?.length > 0) {
    const response = responses[0];
    // Logger(`response: ${JSON.stringify(response)}`);

    if (!response.isSuccess) {
      throw new Error(response.message);
    }
  } else {
    throw new Error(`Cyber: error finalizing: no responses!`);
  }
};

const Finalizing = () => (
  <>
    <LargeText style={{ marginBottom: 20 }}>Finalizing policy...</LargeText>

    <Spinner />
  </>
);

const ChubbFinalizingScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Chubb Finalizing'>) => {
  const client = useApolloClient();
  const [error, setError] = useState();

  const onNextAsync = async () => {
    try {
      await finalize(client);
      Logger(`Cyber: updating user state`);
      await UpdateUserStateOnServer(client, USER_STATE_COMPLETE);
      Logger(`Cyber: complete`);
      await navigation.navigate(ChubbCompleteScreenName);
    } catch (e) {
      // Logger(`Cyber: error finalizing ${JSON.stringify(e?.message || e)}`);
      setError(e?.message || e);
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      header: () => <></>,
      gestureEnabled: false,
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      onNextAsync().catch((e) =>
        Logger(`
          ${e.stack}
          ${e.name}
          ${e.message}`)
      );
    }, [])
  );

  const Body = () =>
    !error ? <Finalizing /> : <ErrorString>{error}</ErrorString>;

  return (
    <ContentUnified title={'Finalizing'} addingProduct={true}>
      <VerticalCenter style={{ flex: 1 }}>
        <LogoImage resizeMode={'contain'} source={LogoLargeImage} />

        <Body />
      </VerticalCenter>
    </ContentUnified>
  );
};

export { ChubbFinalizingScreen };
