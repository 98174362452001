import React from 'react';
import { Platform } from 'react-native';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Sentry from './Sentry';
import { Logger } from './Helper/Logger';

interface Props {
  children: React.ReactNode;
}

function initPaymentsForWeb() {
  try {
    return loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  } catch (err) {
    Logger(`error loading stripe`);
    Sentry.captureException(err);
    return null;
  }
}

export default function PaymentsProvider({ children }: Props): JSX.Element {
  // Payments for iOS is taken care of in iOS source code
  if (Platform.OS === 'web') {
    const stripe = initPaymentsForWeb();
    return <Elements stripe={stripe}>{children}</Elements>;
  }
  return <>{children}</>;
}
