import React, { useCallback, useLayoutEffect, useState } from 'react';
import { WebView } from 'react-native-webview';
import { useFocusEffect } from '@react-navigation/native';
import { Logger } from '../Helper/Logger';
import { GetTokenFromAsync } from '../Helper/UserHelpers';
import GetGraphqlRoot from '../Helper/GetGraphqlRoot';
import { StackScreenProps } from '@react-navigation/stack';
import {
  ChubFinalizingScreenName,
  RootStackParamList,
} from '../../screen-config';
import { ContentUnified, OnlyBlock } from '../Components/ContentShared';
import { LoadingUnified } from '../Components/LoadingUnified';
import { Loading } from '../Components/Loading';
import { isNativeOrMobileWeb } from '../Helper/DeviceHelper';
import { WebViewMessageEvent } from 'react-native-webview/lib/WebViewTypes';

const fetchCyberPaymentUrl = async (token) => {
  const url = `${GetGraphqlRoot().media}/integrations/cyber/payment_url`;

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response.json();

    // Logger(`response: ${JSON.stringify(json)}`);

    if (json?.message) {
      throw new Error(json.message);
    }

    return json;
  } catch (err) {
    Logger(`fetchCyberPaymentUrl: error: ${JSON.stringify(err.message)}`);
    return null;
  }
};

const ChubbPaymentScreen = ({
  navigation,
}: StackScreenProps<RootStackParamList, 'Chubb Payment'>) => {
  const [loaded, setLoaded] = useState(false);
  const [uri, setUri] = useState<string>();

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Cyber Payment Info',
      headerBackTitleVisible: false,
      gestureEnabled: false,
      headerShown: isNativeOrMobileWeb(),
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      GetTokenFromAsync().then((token) => {
        fetchCyberPaymentUrl(token).then((r) => {
          if (r?.Link) {
            Logger(`cyber url: ${r?.Link}`);
            setUri(r.Link);
          }
          setLoaded(true);
        });
      });
    }, [])
  );

  const onMessage = ({ nativeEvent }: WebViewMessageEvent): void => {
    const { data } = nativeEvent;

    if (data === 'PaymentAuthorized') {
      Logger(`ChubbPayment: authorized`);
      navigation.navigate(ChubFinalizingScreenName);
      return;
    }

    if (data === 'PaymentError') {
      Logger(`ChubbPayment: error`);
      return;
    }

    Logger(`ChubbPayment: unrecognized event: ${JSON.stringify(data)}`);
  };

  if (loaded) {
    return (
      <ContentUnified
        title={'Payment'}
        ChildrenWrapper={OnlyBlock}
        addingProduct={true}>
        <WebView
          source={{ uri }}
          startInLoadingState={true}
          onMessage={onMessage}
          overScrollMode={'never'}
          bounces={false}
          renderLoading={() => <Loading loadingText={''} />}
        />
      </ContentUnified>
    );
  } else {
    return <LoadingUnified title={'Payment'} addingProduct={true} />;
  }
};

export default ChubbPaymentScreen;
