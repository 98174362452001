import React, { useState } from 'react';
import styled from 'styled-components/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import Popup from '../Components/Popup';
import { PlainView, Spacer } from '../Components/SimpleComponents';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import WaffleText from '../Components/WaffleText';
import { CFMemberCenterUrl } from '../Helper/CFHelper';
import { Platform } from 'react-native';
import { CFMemberCenterScreenName, HomeScreenName } from '../../screen-config';
import CustomLinking from '../Helper/CustomLinking';
import { useNavigation } from '@react-navigation/native';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

const Header = styled(WaffleText)`
  font-size: 23px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
`;

export const LinkToCFPetPopup = ({
  popup,
  setPopup,
  children,
  doComplete,
}: any) => {
  const navigation = useNavigation();
  const userTracking = useUserTracking();

  async function onContinueAsync() {
    await setPopup(false);
    await userTracking.publishExternalPolicyClicked('Pet', 'ASPCA');

    if (Platform.OS === 'web') {
      navigation.navigate(HomeScreenName);
      await CustomLinking.openURL(CFMemberCenterUrl, '_blank');
      // window.open(ArchMemberCenterUrl, '_blank');
    } else {
      navigation.navigate(CFMemberCenterScreenName);
    }
    doComplete && (await doComplete());
  }

  return (
    <Popup
      visible={popup}
      onDismiss={() => {
        setPopup(false);
      }}>
      <PlainView style={{ alignItems: 'center', width: '100%' }}>
        <Header>ASPCA® Member Center</Header>

        {children}

        <WaffleButtonAsync name={'Continue'} onPress={onContinueAsync} />
      </PlainView>
    </Popup>
  );
};

export const LinkToCFPet = ({ children }: any) => {
  const [popup, setPopup] = useState(false);

  return (
    <>
      <Icon
        name={'file-document-outline'}
        size={25}
        onPress={() => setPopup(true)}
      />
      <Spacer y={0.5} />
      <WaffleText style={{ fontSize: 8 }}>View Policy</WaffleText>
      <LinkToCFPetPopup popup={popup} setPopup={setPopup}>
        {children}
      </LinkToCFPetPopup>
    </>
  );
};
