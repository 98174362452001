import React from 'react';
import styled from 'styled-components/native';

import Popup from '../Components/Popup';
import { PlainView } from '../Components/SimpleComponents';
import { WaffleButtonAsync } from '../Components/WaffleButtonAsync';
import WaffleText from '../Components/WaffleText';
import { GoMemberCenter } from '../Helper/ArchHelper';

const Header = styled(WaffleText)`
  font-size: 23px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
`;

export const LinkToArchPopup = ({
  popup,
  setPopup,
  children,
  doComplete,
}: any) => (
  <Popup
    visible={popup}
    onDismiss={() => {
      setPopup(false);
    }}>
    <PlainView style={{ alignItems: 'center', width: '100%' }}>
      <Header>Claims Portal</Header>

      {children}

      <WaffleButtonAsync
        name={'Continue'}
        onPress={async () => {
          await setPopup(false);
          await GoMemberCenter();
          doComplete && (await doComplete());
        }}
      />
    </PlainView>
  </Popup>
);
